import { useState } from "react";
import { PropTypes } from "prop-types";

export default function Question({ question, answer }) {
  const [open, setOpen] = useState(false);

  return (
    <section>
      <div className="div-flex">
        <h4>{question}</h4>
        <div onClick={() => setOpen(!open)}>
          {open ? <span>↑</span> : <span>↓</span>}
        </div>
      </div>
      {open ? (
        <div open={open} className="panel">
          <p>{answer}</p>
        </div>
      ) : null}
    </section>
  );
}

Question.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};
