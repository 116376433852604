import Question from "./Question";
import image from "../Images/illustration-woman-online-mobile.svg";

export default function Accordeon() {
  return (
    <div className="acc-style">
      <img src={image} alt="" />
      <h1>FAQ</h1>
      <Question
        question="How many friends can I invite?"
        answer="As many as you can invite, Tell your friends about us!"
      />
      <Question
        question="What is the maximum size upload?"
        answer="No more than 2GB. All files in your account must fit the allotted storage space."
      />
      <Question
        question="How do I reset my password?"
        answer="There is an option for you, really easy."
      />
      <Question
        question="Can I cancel my suscription?"
        answer="Yes, you can cancel anytime, no questions asked."
      />
      <Question
        question="Do you provide additional support?"
        answer="Anytime you need us, we'll be there!"
      />
    </div>
  );
}
